
    import Vue from 'vue'

    export default Vue.extend({
        name: 'NCommonTitle',
        props: {
            title: {
              type: String
            },
            noTop: {
                type: Boolean
            },
            size: {
                type: String,
                default: 'small'
            }
        },
        data () {
            return {
            }
        }
    })
